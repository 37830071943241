import React from 'react';
import PortableText from 'react-portable-text';

// Block Components
import { PageLink, PathLink } from '../ui/links';

const serializers = {
  h1: (props) => <h1 {...props} className="text-2xl md:text-4xl mb-2" />,
  h2: (props) => (
    <h2 {...props} className="text-2xl md:text-3xl leading-loose md:leading-relaxed mb-2" />
  ),
  h3: (props) => <h3 {...props} />,
  h4: (props) => <h4 {...props} />,
  h5: (props) => <h5 {...props} className="border-b-2 pb-2 border-oslo-green" />,
  h6: (props) => <h6 {...props} />,
  'super-script': (props) => <sup>{props.children}</sup>,
  'sub-script': (props) => <sub>{props.children}</sub>,
  pageLink: (props) => <PageLink {...props} className="underline font-sansMedium" />,
  pathLink: (props) => <PathLink {...props} className="underline font-sansMedium" />,
  normal: (props) => <p {...props} className="leading-relaxed" />,
};
// eslint-disable-line

export const PortableTextBlock = ({ text, isAnimated = false, ...props }) => {
  return (
    text && (
      <PortableText
        content={text}
        serializers={serializers}
        renderContainerOnSingleChild={true}
        {...props}
      />
    )
  );
};
