import React from 'react';
import { Link } from 'gatsby';

// Hooks & Querys
import { useSiteConfig } from '../../GraphQl/useSiteConfig';

// SVG
import Logo from '../../svg/logo.svg';

// Components
import { PortableTextBlock } from '../sanity/portableTextBlock';

export const Footer = () => {
  const { footerText } = useSiteConfig();
  return (
    <footer className="px-gutter py-16 flex flex-col md:flex-row md:justify-between">
      <div className="grid gap-6 mb-6 md:grid-flow-col lg:gap-x-20">
        {footerText.map(({ _rawText }) => (
          <PortableTextBlock text={_rawText} className="prose prose-sm" />
        ))}
      </div>
      <Link className="w-32">
        <Logo />
      </Link>
    </footer>
  );
};
