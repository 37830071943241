import React, { useState } from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import { motion } from 'framer-motion';
// Hooks & querys
import { useToggle } from '../../hooks';
import { useAppContext } from '../../state';

// Components
import { MenuToggle } from './menuToggle';
import { NavDraw } from './navDraw';
import Logo from '../../svg/logo.svg';

export const Header = () => {
  const [navOpen, setNavOpen, toggleNav] = useToggle(false);
  const [headerPinned, setHeaderPinned] = useState(false);

  const { isHome, splitNav } = useAppContext();

  const closeNav = () => setNavOpen(false);

  return (
    <>
      <Headroom
        style={{ zIndex: '2000' }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <motion.header
          className={`flex justify-between items-center py-5 px-gutter transition-all lg:py-7 ${
            headerPinned && 'bg-white text-oslo-green'
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut', delay: 0.8 }}
        >
          <Link
            to="/"
            className={`w-32 transition-all lg:w-36 ${
              isHome && !headerPinned ? 'opacity-0' : 'opacity-100'
            }
            ${
              splitNav
                ? navOpen
                  ? !headerPinned && 'md:text-oslo-blue'
                  : !headerPinned && 'text-oslo-blue'
                : navOpen && 'text-oslo-green md:text-inherit'
            } 
            `}
          >
            <Logo />
          </Link>
          <div
            className={`transition-colors ${
              navOpen
                ? 'text-oslo-green'
                : isHome && !headerPinned
                ? 'text-oslo-blue'
                : splitNav && !headerPinned && 'text-oslo-blue lg:text-inherit'
            }`}
          >
            <MenuToggle isOpen={navOpen} onClick={toggleNav} />
          </div>
        </motion.header>
      </Headroom>
      <NavDraw isOpen={navOpen} onClose={closeNav} />
    </>
  );
};
