import React, { createContext, useContext } from 'react';

// Hooks
import { useToggle } from '../hooks';

export const AppContext = createContext({
  splitNav: false,
  isHome: false,
  themeDark: false,
  hideFooter: false,
});

export const PageWrapper = ({ children }) => {
  const [splitNav, setSplitNav] = useToggle(false);
  const [isHome, setIsHome] = useToggle(false);
  const [hideFooter, setHideFooter] = useToggle(false);

  return (
    <AppContext.Provider
      value={{
        splitNav,
        setSplitNav,
        isHome,
        setIsHome,
        hideFooter,
        setHideFooter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
