import React from "react"

import { PageLink, PathLink } from "../ui"

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case "pageLink":
        return <PageLink {...rest} />
      case "pathLink":
        return <PathLink {...rest} />

      default:
        return null
    }
  }
  return <LinkComponent />
}
