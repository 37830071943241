export const usePadBottom = (size) => {
  switch (size) {
    case 'xs':
      return 'pb-5 md:pb-12';
    case 'small':
      return 'pb-20 md:pb-40';
    case 'medium':
      return 'pb-32 md:pb-64';
    case 'large':
      return 'pb-48 md:pb-96';
    default:
      return null;
  }
};
