import React from "react"
import { Link } from "gatsby"

// Hooks & Querys
import { useSanityPath } from "../../hooks"

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  )
}

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  )
}
