import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// hooks & querys
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { useScrollFreeze } from '../../hooks';

// Components
import { NavLink } from './navLink';
import { PortableTextBlock } from '../sanity/portableTextBlock';

// Animation
const outerVars = {
  enter: {
    x: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
      delayChildren: 0.3,
      staggerChildren: 0.1,
    },
  },
  exit: {
    x: '100%',
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const innerVars = {
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    x: '30%',
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

export const NavInner = ({ onClose }) => {
  const { mainNav, _rawContactDetails } = useSiteConfig();
  useScrollFreeze();
  return (
    <>
      <motion.div
        className="h-screen w-full fixed top-0 right-0 z-1000 grid grid-rows-2 pr-gutter bg-white text-oslo-green md:w-1/3 lg:pb-16 lg:pt-24 xl:pt-32"
        initial="exit"
        animate="enter"
        exit="exit"
        variants={outerVars}
      >
        <motion.nav
          className="grid gap-y-6 text-right w-full lg:gap-y-12 self-end"
          variants={innerVars}
        >
          {mainNav.map((item) => (
            <NavLink
              {...item}
              className="text-3xl md:text-4xl xl:text-5xl"
              onClick={onClose}
              activeClassName="underline"
            />
          ))}
        </motion.nav>
        <motion.div variants={innerVars} className="self-start lg:self-end">
          <PortableTextBlock
            text={_rawContactDetails}
            className="prose text-right max-w-none mt-10"
          />
        </motion.div>
      </motion.div>
      <motion.div
        className="w-full h-full bg-oslo-green opacity-40 fixed top-0 left-0 block z-75"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.7 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.5,
          ease: 'easeOut',
        }}
        onClick={onClose}
      />
    </>
  );
};

export const NavDraw = ({ isOpen, onClose }) => {
  return <AnimatePresence>{isOpen && <NavInner onClose={onClose} />}</AnimatePresence>;
};
